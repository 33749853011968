import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { device, colors } from './GlobalStyles';
import check from '../images/icons/check-mark.svg';
import imageLeft from '../images/technology/robot-arm.png';
import imageRight from '../images/technology/screen-big.png';
import mobile from '../images/technology/screen-and-arm.png';

const Contain = styled.section`
    h1 {
        text-align: center;
        font-size: 24px;
        margin: 0.5em;
    }
    @media ${device.laptop} {
        padding-top: 1em;
    }
`;

const StyledTechnology = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        margin-top: 2em;
        max-width: 320px;
    }
    .desktop-image-left,
    .desktop-image-right {
        display: none;
    }
    .mobile-image {
        display: block;
    }
    div {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    button {
        border: 1px solid ${colors.grey};
        padding: 1.5em 3em;
        background: none;
        margin-bottom: 4em;
        &:hover {
            cursor: pointer;
            background: ${colors.grey};
            color: ${colors.white};
        }
    }
    ul {
        padding: 0;
        margin: 1em 0;
        list-style-type: none;
        li {
            display: flex;
            font-size: 24px;
            font-weight: 700;
            align-items: center;
            margin: 1em 0em;
            img {
                margin-right: 1em;
            }
        }
    }
    @media ${device.tablet} {
        .mobile-image {
            margin: 2em 0em;
        }
    }
    @media (min-width: 1280px) {
        padding: 2em 0em;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        p {
            max-width: 420px;
        }
        .mobile-image {
            display: none;
        }

        .desktop-image-left,
        .desktop-image-right {
            margin-bottom: 5em;
            width: 300px;
            display: block;
        }
        .desktop-image-right {
            width: 280px;
        }
        ul {
            margin: 3em 0;
        }
        div {
            margin: 0em 3em;
            max-width: 500px;
        }
    }
    @media ${device.desktop} {
        .desktop-image-left {
            width: 390px;
            margin-right: 0em;
        }
        .desktop-image-right {
            width: 340px;
        }
    }
`;

export const Technology: React.FC = ({ techPage }) => {
    return (
        <Contain>
            <div style={{ background: `${colors.white}` }}>
                <h2 style={{ paddingTop: `${techPage ? '2.0em' : '1em'}`, textAlign: 'center' }}>
                    ADVANCED TECHNOLOGY
                </h2>
                {techPage && <h1> Prosthodontics & Implant Therapy Technology in Tampa Florida</h1>}
                <StyledTechnology>
                    <img className="desktop-image-left" alt="desktop-right" src={imageRight} />
                    <img className="mobile-image" alt="mobile" src={mobile} />
                    <div>
                        <p>
                            We use cutting-edge dental technology to provide you with safe and
                            effective results!
                        </p>
                        <ul>
                            <li>
                                <img src={check} alt="check mark" /> High-Def Images
                            </li>

                            <li>
                                <img src={check} alt="check mark" /> Increased Diagnostics
                            </li>

                            <li>
                                <img src={check} alt="check mark" /> Same Day Crowns
                            </li>
                        </ul>
                        {techPage ? null : (
                            <Link to="/dental-technology/">
                                <button>Our Technology</button>
                            </Link>
                        )}
                    </div>
                    <img className="desktop-image-right" src={imageLeft} alt="desktop-left" />
                </StyledTechnology>
            </div>
        </Contain>
    );
};
